<template>
  <div align="center">
    <section class="e-content" align="left">
      <h3>
        Source #{{ i + 1 }}
        <img
          style="margin-left: 5pt; margin-right: 10pt; height: 30pt"
          src="../assets/nespaper.gif"
        />
        <small style="float: right"
          ><router-link to="/"><br />☜ back to home</router-link></small
        >
      </h3>
      <img src="../assets/divider-1.gif" style="width: 100%" />
      <br />
      <h5>Source Title</h5>
      <h4>{{ source.title }}</h4>
      <small v-html="source.mla"></small>
      <br /><br />
      <h5>Summary</h5>
      <p style="text-indent: 15pt">
        {{ source.summary }}
      </p>
      <br />
      <h5>Analysis</h5>
      <p style="text-indent: 15pt">
        {{ source.analysis }}
      </p>
      <br />
      <h5>RAVEN</h5>
      <table class="table table-bordered table-striped table-hover">
        <thead>
          <tr>
            <th>What are the main points and topics?</th>
            <th>How did the authors get their information?</th>
            <th>How does the source relate to my inquiry?</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ source.raven["1a"] }}</td>
            <td>{{ source.raven["1b"] }}</td>
            <td>{{ source.raven["1c"] }}</td>
          </tr>
        </tbody>
      </table>
      <table class="table table-bordered table-striped table-hover">
        <tbody>
          <tr>
            <th>R</th>
            <td>{{ source.raven.r }}</td>
          </tr>
          <tr>
            <th>A</th>
            <td>{{ source.raven.a }}</td>
          </tr>
          <tr>
            <th>V</th>
            <td>{{ source.raven.v }}</td>
          </tr>
          <tr>
            <th>E</th>
            <td>{{ source.raven.e }}</td>
          </tr>
          <tr>
            <th>N</th>
            <td>{{ source.raven.n }}</td>
          </tr>
        </tbody>
      </table>
    </section>
  </div>
</template>

<script>
export default {
  name: "source-page",
  data() {
    return {
      i: Number(this.$route.params.id),
      sources: [
        {
          title: "New insights into links between national income and obesity.",
          mla:
            '"New insights into links between national income and obesity." <i>NewsRx Health</i>, 31 May 2020, p. 398. <i>Gale In Context: High School</i>, link.gale.com/apps/doc/A624794683/SUIC?u=port&sid=SUIC&xid=1828fc72. Accessed 14 Jan. 2021.',
          summary: `In this article the journalist explains their discovery that rising GDP of a country and the obesity rate have a linear relationship. The study shows that a 1 percent rise in a country's GDP per capita is associated with an average increase in obesity rates of 1.23 percent for men and 1.01 percent for women. The analysis did not address how income inequality within countries might impact the relationship between national income and obesity. The author went on to explain how there’s a rising number of policies being made to combat this trend in newly-developing countries.`,
          analysis: `While I hadn’t heard of this source before, NewsRx Health sounds reputable in the fact that this article is about public health. There also seems to be as little bias as possible, as the author analyzed the data from a very objective point of view and the outcome wouldn’t benefit them one way or another. The article does a good job of defining the relationships between GDP and obesity rate while still pointing out where the data is inconclusive. The way this article discusses obesity in a larger scope will fit into my research well to provide some context.`,
          raven: {
            "1a": "As a countries GDP rises, so does it’s obesity rate",
            "1b":
              "A study of obesity rates of 142 countries over a span of 39 years",
            "1c": "Provides evidence of a trend relating to obesity",
            r:
              "From NewsRx Health, seems reputable for an article about health",
            a: "The author themselves is the one analyzing the data",
            v: "The author wouldn’t benefit from the outcome one or another",
            e:
              "The author seemed knowledgeable about statistics, which is what the article was mostly about",
            n:
              "There doesn’t seem to be any bias in the writing, it’s all very objective",
          },
        },
        {
          title: "Obesity rate low for low-income children.",
          mla:
            '"Obesity rate low for low-income children." <i>Policy & Practice</i>, vol. 64, no. 4, Dec. 2006, p. 7. <i>Gale In Context: High School</i>, link.gale.com/apps/doc/A159080265/SUIC?u=port&sid=SUIC&xid=2e423d9d. Accessed 7 Jan. 2021.',
          summary: `The article starts by stating that America’s lowest income adults have a higher incidence of obesity than higher income adults, but that surprisingly the same isn’t true for low-income children. "These findings were counter to our hypotheses," said Sandra Hofferth, professor of family studies at the University of Maryland. The purpose of the study was to determine if school food programs were promoting obesity, which the results seemed to prove false.`,
          analysis: `The thesis of this article is about policy regarding food programs in schools, so it seems appropriate that the publisher is Policy & Practice Magazine, which I would consider a reputable source. I trust the validity of the information as the author cited the University of Maryland as the source of the study and even admitted their hypothesis was wrong. It’s unknown whether the author is an authority on the subject of health, but they appeared to stay unbiased regarding the facts. This article will serve to provide some evidence in my research surrounding rates of obesity vs income.`,
          raven: {
            "1a":
              "Low-income children do not have the same high obesity rates as low-income adults",
            "1b": "American Public Human Services Association",
            "1c": "Discusses rates of obesity in a certain group of people",
            r:
              "From Policy & Practice Magazine, I’ve never heard of it but I don’t read a lot of magazines",
            a:
              "The author reported these findings and admitted to their hypothesis being incorrect",
            v: "The author doesn’t benefit from the outcome one way or another",
            e: "It’s unknown whether the author is an authority on the subject",
            n:
              "The author stated their belief but it didn’t affect the reporting of the facts",
          },
        },
        {
          title: "OBESITY SOCIETY: IT IS A CHRONIC DISEASE.",
          mla:
            '"OBESITY SOCIETY: IT IS A CHRONIC DISEASE." <i>USA Today</i>, vol. 147, no. 2885, Feb. 2019, p. 1+. <i>Gale In Context: High School</i>, link.gale.com/apps/doc/A576453736/SUIC?u=port&sid=SUIC&xid=69429582. Accessed 4 Jan. 2021.',
          summary: `The purpose of this article is to inform the public about obesity being a disease. “Obesity meets all criteria for being a disease, and therefore should be characterized as such. The recognition impacts how individuals are viewed and should reduce the stigma associated with it. The statement is needed so that the public and policy makers can make informed decisions regarding the health care surrounding obesity.” States Cathy Kotz, professor at University of Minnesota. The conclusion of the article is essentially that fighting the obesity epidemic is a group effort and shouldn’t be forced onto the individual.`,
          analysis: `This article is from USA Today, which I would consider a reputable source. The author is citing professionals in the industry from organizations such as the Pennington Biomedical Research Center and University of Minnesota's Department of Integrative Biology and Physiology. This reporting is objective from the professional’s point of view and shows little bias, seeing as the author wouldn’t benefit one or another from the outcome. The one slight bias it has is in favor of public health, which wouldn’t negatively affect my research anyways. I think this article will be helpful in citing professional’s opinions on the obesity epidemic in my paper.`,
          raven: {
            "1a":
              "Obesity should be treated the same as other diseases and new policies put in place to reflect that.",
            "1b": "Interviews with health professionals and professors",
            "1c":
              "Gives some context as to the severity of obesity and how widespread it is",
            r: "from USA Today, what I would consider a reputable source",
            a:
              "The author is citing professionals in the industry, which is probably the best one can get",
            v: "The author does not benefit from the outcome",
            e:
              "It is unknown whether the author is an authority on the subject",
            n:
              "The author is biased in favor of public health, which makes sense in the context of the title",
          },
        },
      ],
    };
  },
  mounted() {
    console.log(this.$data);
  },
  computed: {
    source() {
      return this.sources[this.i];
    },
  },
  watch: {
    $route(fr, to) {
      this.i = Number(this.$route.params.id);
    },
  },
};
</script>

<style scoped></style>
